import React, { useEffect, useState, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import ContentfulLink from "components/header/contentfulLink"
import { searchActiveMenu } from "./helpers"
import icons from "helpers/icons"
import styles from "styles/components/header/header.module.scss"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const SublinkItem = ({ link, classExtend, activeSubMenu }) => {
  return <ContentfulLink isHeader="true" {...link} className={`${styles.dFlex} ${classExtend} ${styles.alignCenter} ${styles.submenuItem} ${activeSubMenu === link.internalLink ? styles.currentMenu : ''}`}>
    {link.linkText}
  </ContentfulLink>
}

const DropdownLink = ({ index, link, lastItem, pageJson, typeHeader }) => {
  const ref = useRef(null)
  const [topPosition, setTopPosition] = useState(0)
  const [activeMenu, setActiveMenu] = useState(false)
  const [activeSubMenu, setSubActiveMenu] = useState('')
  const sublinks = link.links || link.menus || link.sublinks || []
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  link.buttonLink = link.internalLink || link.externalLink

  const getFirstPathName = (pathname = '') => {
    return pathname.split('/').filter(path => path !== '')[0]
  }
  useEffect(() => {
    const currentMenu = searchActiveMenu(link, pageJson)
    if (currentMenu.current) {
      setSubActiveMenu(currentMenu.current.buttonLink)
    }
    const pathname = window.location.pathname
    if (link.buttonLink) {
      setActiveMenu(getFirstPathName(pathname) === (link.linkText || link.title).toLowerCase().replace(new RegExp(' ', 'i'), '-'))
    }
    if (pathname.includes('blog') && (link.linkText || link.title).toLowerCase() === 'resources') {
      setActiveMenu(true)
      setSubActiveMenu('/blog/')
    }
    window.addEventListener('scroll', () => {
      setDropdownIsOpen(false)
    })
    setTimeout(() => {
      calcTopSubmenu()
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, pageJson])

  const calcTopSubmenu = () => {
    if (!ref?.current) return
    const rect = ref.current.getBoundingClientRect()
    const $header = document.getElementsByTagName('header')
    const rectHeader = $header[0].getBoundingClientRect()
    const $alertBar = document.getElementById('alert-bar')
    let heightAlert = 0

    if ($alertBar && rectHeader.top > 0 && typeHeader === 'dynamic') {
      // positionHeader = $header[0].getBoundingClientRect().top
      heightAlert = $alertBar.offsetHeight + $alertBar.getBoundingClientRect().top
    }
    setTopPosition(rect.top + rect.height - heightAlert + 21)
  }


  const openDropdown = () => {
    calcTopSubmenu()
    setDropdownIsOpen(true)
  }

  const closeDropdown = () => {
    setDropdownIsOpen(false)
  }

  return (
    <li
      role="presentation"
      className={`${styles.navItem} ${((link.menus ? sublinks.length : 1) === 1) ? styles.relative : ''}`}
      onMouseOver={openDropdown}
      onFocus={openDropdown}
      onMouseLeave={closeDropdown}
      ref={ref}
    >
      <ContentfulLink
        isHeader="true"
        {...link}
        className={cx('link', 'relative', 'pointer', {
          noSublink: (sublinks || []).length === 0,
          active: dropdownIsOpen,
          menuActive: activeMenu
        })}
        ctaClassName={styles.cta}
      >
        <div className={styles.menuText}>{link.linkText || link.title}</div>
        {link.links&&<span className={`${styles.arrowExpand} ${styles.menuItem} ${dropdownIsOpen ? styles.expaned : ''}`} >{icons.arrowMenu}</span>}
      </ContentfulLink>

      {sublinks.length>0 && (<div
        role="presentation"
        className={styles.dropdown}
        onMouseOver={openDropdown}
        onFocus={openDropdown}
        onMouseLeave={closeDropdown}
      >
        <CSSTransition
          in={dropdownIsOpen} /* index ===  */ /* dropdownIsOpen */
          // in={index === 0} /* index ===  */ /* dropdownIsOpen */
          timeout={300} /* required */
          mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
          unmountOnExit
          classNames={{
            enter: styles.overflowHidden + ' ' + styles.animateDropdownEnter,
            enterActive: styles.overflowHidden + ' ' + styles.animateDropdownEnterActive,
            exit: styles.overflowHidden + ' ' + styles.animateDropdownExit,
            exitActive: styles.overflowHidden + ' ' + styles.animateDropdownExitActive,
            exitDone: styles.overflowHidden + ' ' + styles.animateDropdownExitDone,
          }}
        >
          <div
            className={`${styles.dropdownContent} ${!link.menus && link.links && styles.listDropdown} ${styles['col' + (link.menus ? sublinks.length : 1)]} ${lastItem ? styles.lastItem : ''}`}
            style={[2, 3].includes(sublinks.length) && link.menus ? {
              top: topPosition + 'px',
              position: 'fixed'
            } : {}}
          >
            {/* <PaddedContent> */}
              {!link.menus && link.links && 
                <div className={styles.submenuItemList}>
                  <div className={styles.activeMenu}>
                    <span>{link.linkText || link.title}</span>
                    {sublinks.length>0&&<span className={`${styles.arrowExpand} ${styles.expaned}`} >{icons.arrowMenu}</span>}
                  </div>
                  {(sublinks[0]?.links || link.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
              </div>}
            {/* </PaddedContent> */}
          </div>
        </CSSTransition>
      </div>)}
    </li>

  )
}

export default DropdownLink
