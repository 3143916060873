import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import { searchActiveMenu } from "./helpers"
import styles from "styles/components/header/secondary-menu.module.scss"
import PaddedContent from "components/paddedContent"
import { useContentfulSite } from "hooks/use-contentful-site"
import classNames from "classnames/bind"
import ContentfulLink from "components/header/contentfulLink"

const cx = classNames.bind(styles)

const SecondaryMenu = ({ secondaryMenu, page, pinHeader }) => {
  const site = useContentfulSite()
  const wrapperRef = useRef()
  const anchorRef = useRef()
  const activeMenu = searchActiveMenu(site, page)
  const links = activeMenu?.links || []
  const loginLink = page?.loginLink || '/'
  const [heightMenu, setHeightMenu] = useState(70)
  const [positionMenu, setPositionMenu] = useState(0)
  const initHandler = () => {
    if (!wrapperRef?.current) {
      return
    }
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const anchorRect = anchorRef.current.getBoundingClientRect()
    let position = 0
    if (anchorRect.top > 0) {
      position = anchorRect.top
    }
    setHeightMenu(wrapperRect.height)
    setPositionMenu(position)

  }
  useEffect(() => {
    let oldPosition = {top: 0, height: 0}
    let count = 0
    const intervalCalHeight = setInterval(() => {
      if (!wrapperRef?.current) return
      const wrapperRect = wrapperRef.current.getBoundingClientRect()
      count ++
      if (oldPosition.top !== wrapperRect.top || oldPosition.height !== wrapperRect.height) {
        count = 0
        oldPosition.top = wrapperRect.top
        oldPosition.height = wrapperRect.height
        initHandler()
      }
      if (count > 100) {
        clearInterval(intervalCalHeight)
      }
    }, 50)
    window.addEventListener("resize", initHandler)
    window.addEventListener("scroll", initHandler)
    return () => {
      window.removeEventListener("resize", initHandler)
      window.removeEventListener('scroll', initHandler)
    }
  })

  return <>
    <section className={styles.secondaryMenuSection} ref={wrapperRef} style={{top: positionMenu + 'px'}}>
      <PaddedContent>
        <div className={cx("wrapper", { pinHeader: pinHeader })}>
          {(secondaryMenu.showSection.logo && activeMenu.logo) && <div className={`${styles.logoSectiton} ${styles.flex0}`}>
            <Link to={activeMenu?.current?.buttonLink || activeMenu?.parent?.parent?.buttonLink || activeMenu?.parent?.buttonLink}>
              <img src={activeMenu.logo?.file?.url} alt={activeMenu.logo?.title} />
            </Link>
          </div>}
          <div className={`${styles.submenus} ${styles.flex1} ${links.length > 5 ? styles.decreaseSpace : ''}`}>
            {secondaryMenu.showSection.submenus && <ul>
              
              {links.map((menu, mIndex) => <li key={`secondary-sub-menu-${mIndex}`} className={styles.linkMenu}>
                <Link data-text={menu.linkText} to={menu.internalLink} className={activeMenu.slug === menu.internalLink ? styles.activeMenu : ''}>
                  <span>{menu.linkText || menu.title}</span>
                </Link>
              </li>)}
            </ul>}
          </div>
          {secondaryMenu.showSection.login && <div className={`${styles.loginBtnWrap} ${styles.flex0}`}>
            <ContentfulLink className={`${styles.loginBtn}`} externalLink={loginLink}>
              Log in
            </ContentfulLink>
          </div>}
        </div>
      </PaddedContent>
    </section>
    <div style={{
      height: heightMenu + 'px'
    }} ref={anchorRef}></div>
  </>
}
export default SecondaryMenu
