import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PaddedContent from "components/paddedContent"
import DropdownLinkMobile from "components/header/dropdownLinkMobile"
import { useContentfulSite } from "hooks/use-contentful-site"
import { CSSTransition } from "react-transition-group"
import ContentfulLink from "components/header/contentfulLink"

import styles from "styles/components/header/mobile-menu.module.scss"
//use headerStyles to match location of logo and button in the top part of the menu
import headerStyles from "styles/components/header/header.module.scss"
import Logo from "images/logos/vintrace-header-logo.svg"
import Close from "images/icons/close-menu.svg"
import icons from "helpers/icons"
import menuLogo from "images/logos/menu-logo.png"
import HubspotForm from "sections/hubspotForm"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

/**
 * Mobile Menu
 * @param {[Object]} links list of link objects
 * @param {Function} closeMenu function to close the menu
 */
const MobileMenu = ({links, isOpen, closeMenu, handleMenuClick, pageJson, languageDropdown, lan, languageVal }) => {
  const site = useContentfulSite()
  const [isHome, setIsHome] = useState(pageJson ? pageJson?.title === 'Home' : true)
  const isBrowser = typeof window !== "undefined"
  // form
  const popupForm = site.popupForm
  const getADemoLinks = site.getADemoLinks
  const [activeMenu, setActiveMenu] = useState(-1)
  const [openSubMenu, setOpenSubmenu] = useState(false)
  const [expandLogin, setExpandLogin] = useState(false)
  const imageLogo = <img src={menuLogo} alt="Menu" className={styles.menuLogo} />
  const ref = useRef(null)

  const toggleSubmenu = (index, dropdownIsOpen) => {
    setActiveMenu(!dropdownIsOpen ? -1 : index)
    setOpenSubmenu(dropdownIsOpen)
  }

  const expandArrow = (e) => {
    e.preventDefault()
    setExpandLogin(!expandLogin)
  }

  useEffect(() => {
    if (isOpen) {
      setActiveMenu(-1)
      setOpenSubmenu(false)
    }
  }, [isOpen])

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setExpandLogin(false)
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // })

  const chooseLan = (e) => {
    if (e.target && e.target.getAttribute("value")) {
      const label = e.target.innerText

      if (isBrowser && label && label !== lan) {
        const val = e.target.getAttribute("value")
        let link = window.location.pathname.replace(`${languageVal}/`, "")
        if (val === "en-US") {
          window.location.href = `${window.location.protocol}//${window.location.host}${link}`
        } else {
          if (isHome) {
            window.location.href = `${window.location.protocol}//${window.location.host}/${val}/home`
          } else if (!window.location.href.includes(`/${val}/`)) {
            window.location.href = `${window.location.protocol}//${window.location.host}/${val}${link}`
          }
        }
      }
    }
  }
  return (
    <CSSTransition
      in={isOpen}
      mountOnEnter
      unmountOnExit
      timeout={300}
      classNames={{
        enter: styles.menuEnter,
        enterActive: styles.menuEnterActive,
        exit: styles.menuExit,
        exitActive: styles.menuExitActive,
        exitDone: styles.menuExitDone,
      }}
    >
      <div className={styles.wrapper}>
        <PaddedContent>
          <div className={headerStyles.content}>
            <Link
              to="/"
              className={headerStyles.logo + ' ' + styles.logo}
              aria-label="Home"
              onClick={(e) => handleMenuClick(e, "/")}
            >
              <Logo />
            </Link>
            <button
              className={`${headerStyles.menuButton} ${styles.closeBtn}`}
              onClick={closeMenu}
              aria-label="Close Menu"
            >
              <Close />
            </button>
          </div>
          { <div className={styles.topNav}>
            
            <ul className={styles.navListLan}>
                  <li className={styles.relative}>
                    <span role="presentation" className={`${styles.dFlex} ${styles.menuItem} ${styles.alignCenter} ${styles.pointer} ${styles.topNavBtn}`} onBlur={expandArrow} onClick={expandArrow} ref={ref}>
                    {/* {flagImage && <img id="Flag" src={flagImage} className={styles.flagImage} alt=""></img>} */}
                    {lan}
                      <span className={`${styles.arrowExpand} ${expandLogin ? styles.expaned : ''}`} >
                        {icons.arrow}
                      </span>
                    </span>
                    <CSSTransition
                      in={expandLogin} /* dropdownIsOpen */
                      timeout={300} /* required */
                      mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
                      unmountOnExit
                      classNames={{
                        enter: styles.overflowHidden + ' ' + styles.animateDropdownEnter,
                        enterActive: styles.overflowHidden + ' ' + styles.animateDropdownEnterActive,
                        exit: styles.overflowHidden + ' ' + styles.animateDropdownExit,
                        exitActive: styles.overflowHidden + ' ' + styles.animateDropdownExitActive,
                        exitDone: styles.overflowHidden + ' ' + styles.animateDropdownExitDone,
                      }}
                    >
                      <div className={styles.dropdownContent}>
                        <div className={styles.submenuItemList} onClick={chooseLan}>
                          {(languageDropdown || [])
                            .map((Language, index) => <div key={index} value={Language.languageCode} className={`${styles.submenuItem} ${Language.language == lan ? styles.activeMenu : ''}`}>
                              {/* {Language.flag && (<img src={Language.flag?.file.url} className={styles.flagImage} alt=""></img>)} */}
                              {Language.language}
                            </div>)}                
                        </div>
                      </div>
                    </CSSTransition>
                  </li>
                </ul>
          </div> }
          <div className={styles.contentMenu}>
            <nav className={`${styles.nav}  ${openSubMenu ? styles.opened : ''}`}>
              <ul className={styles.navList}>
                {(links || []).map((link, index) => {
                  if (link.title !== "HiddenMenu") {
                    return <DropdownLinkMobile activeMenu={activeMenu} key={link.id} index={index} link={link} toggleSubmenu={toggleSubmenu} pageJson={pageJson} />
                  }
                  return null;
                })}
                <li className={cx('navItem', 'btnCta')}>
                  <div className={`${styles.relative} ${styles.inlineBlock}`}>
                    {popupForm && getADemoLinks.linkText && <HubspotForm {...popupForm} text={getADemoLinks.linkText} customerStyle={styles.cta} />}
                  </div>
                </li>
              </ul>
            </nav>
            {activeMenu === -1 && <div className={styles.contentDescription}>
              <span>Unlock the power of connection</span>
            </div>}
          </div>
        </PaddedContent>
        {imageLogo}
      </div>
    </CSSTransition>
  )
}

export default MobileMenu
