import { useStaticQuery, graphql } from "gatsby"
export const useContentfulSite = () => {
  const { contentfulSite } = useStaticQuery(
    graphql`query SiteLinks {
      contentfulSite(entryTitle: {eq: "Site"}) {
        headerLinks {
          ... on ContentfulMenuItem {
            id
            title
            internalLink
            externalLink
          }
          links {
            id
            linkText
            internalLink
            externalLink
            #icon
            openInNewTab
          }
        }
        footerLinks {
          id
          linkText
          internalLink
          externalLink
          sublinks {
            id
            linkText
            internalLink
            externalLink
          }
        }
        facebookLink
        instagramLink
        linkedinLink
        twitterLink
        youtubeLink
        contactLink
        loginLink
        getADemoLinks {
          id
          internalLink
          linkText
          externalLink
        }
        footerText {
          footerText
        }
        footerContent {
          footerContent
        }
        popupWithAFormHubspot {
          internal {
            content
          }
        }
        languageDropdown {
          language
          languageCode
          #flag {
          #  file {
          #    url
          #  }
          #}
        }
        footerLink {
          externalLink
          linkText
        }
        popupForm {
          id
          formId
          formTarget
          portalId
          subtext {
            subtext
          }
          title
        }
        announcementBar {
          buttonText
          buttonLink
          openInNewTab
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          image {
            file {
              url
            }
            title
          }
        }
      }
    }
    `
  )
  return contentfulSite
}
