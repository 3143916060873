import React from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import "what-input"
import { useBreakpoint } from "hooks/use-breakpoint"
import NoScript from "./noScript"

import styleVars from "styles/variables.scss"
import "styles/components/layout.scss"
import classNames from "classnames"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}


const Layout = ({ children, headerPadding, changedPage, includeSecondaryNav, page, heightAlert, site }) => {
const [isLaptop] = useBreakpoint(styleVars.breakpointLaptop, 100)
 
  return (
    <>
      <Header
        includeSecondaryNav={includeSecondaryNav}
        isLaptop={isLaptop}
        page={page}
        // color={color ? color : "white"}
        heightAlert={heightAlert}
        site={site}
      />
      {/* Update main-wrapper class in layout.scss if header type changes */}
      <main
        className={classNames("main-wrapper", {
          "main-padding": headerPadding,
        })}
      >
        {children}
      </main>
      <NoScript />
        <Footer site={site}/>
    </>
  )
}

export default Layout
