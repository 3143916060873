import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { useContentfulSite } from "hooks/use-contentful-site"
import PaddedContent from "components/paddedContent"
import MobileMenu from "components/header/mobileMenu"
import DropdownLink from "components/header/dropdownLink"
import SecondaryMenu from "./secondary-menu"
import { CSSTransition } from "react-transition-group"
import AlertBar from 'components/alertBar'
import styles from "styles/components/header/header.module.scss"
import Logo from "images/logos/vintrace-header-logo.svg"
import Hamburger from "images/icons/open-menu.svg"
import icons from "helpers/icons"
import classNames from "classnames/bind"
import { getNewLink } from "variables.js"
const cx = classNames.bind(styles)

/**
 * Header
 * @param {Boolean} isLaptop displays either list of links or hamburger menu
 * @param {String} type "static": header stays at the top of the page
 *                      "fixed": header stays fixed to the top of the screen
 *                      "dynamic": header is removed when scrolling down and visible when scrolling up
 * @param {String} color color of link text, can be "white" or "navy". White is default.
 */
const Header = ({ page, includeSecondaryNav, site }) => {
  //pull links from siteMetadata
  let siteLinks = useContentfulSite()
  if (!site) {
    site = siteLinks
  }
  const { announcementBar } = siteLinks
  const secondaryNavOpt = [
    {
      text: 'with Logo, sub-pages within the section, and Log In button',
      showSection: {
        logo: true,
        login: true,
        submenus: true
      }
    },
    {
      text: 'with Logo & Log In button only',
      showSection: {
        logo: true,
        login: true
      }
    }
  ]
  const secondaryMenu = secondaryNavOpt.find(option => option.text === includeSecondaryNav)
  const [type] = useState(secondaryMenu ? 'static' : 'dynamic')

  // form
  const getADemoLinks = site.getADemoLinks

  //state
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [visible, setVisible] = useState(true)
  const [pinHeader, setPinHeader] = useState(false)
  const [expandLogin, setExpandLogin] = useState(false)
  const [isHome, setIsHome] = useState(page ? page?.title === 'Home' : true)

  const [loaded, setLoaded] = useState(false)
  let [isShowAlert, setIsShowAlert] = useState(Boolean(announcementBar))
  const [heightHeader, setHeightHeader] = useState(0)
  const [positionHeader, setPositionHeader] = useState(121)
  // const [heightAnchor, setHeightAnchor] = useState(page && page?.title !== 'Home' && type === 'dynamic' ? 123 : 0)
  const [heightAnchor, setHeightAnchor] = useState(page && type === 'dynamic' ? 123 : 0)
  const anchorRef = useRef()
  const headerRef = useRef()
  // language
  let lan = "English (US)";
  let languageVal = "en-US"
  // let flagImage = ""
  const isBrowser = typeof window !== "undefined"
  // language
  if (isBrowser) {
    const pathname = window.location.pathname
    for (let i = 0; i < siteLinks.languageDropdown.length; i++) {
      const Language = siteLinks.languageDropdown[i]
      if (pathname.includes(`/${Language.languageCode}/`)) {
        lan = Language.language;
        languageVal = Language.languageCode;
        // if(Language.flag){
        //   flagImage = "http:" + Language.flag.file.url
        // }
        break
      }
    }
  }

  const closeAlert = () => {
    sessionStorage.setItem('showAlert', 'hide');
    setIsShowAlert(false)
  }

  const ref = useRef(null)

  //variables
  const scrollTop = useRef()
  const prevScroll = useRef()
  const handleScrollStatic = () => {
    const $header = document.getElementsByTagName('header')
    if (!$header[0]) return
    const reactHeader = $header[0].getBoundingClientRect()

    let position = reactHeader.top + reactHeader.height
    if (position < 0) {
      position = 0
    }
  }
  useEffect(() => {
    if (isBrowser && !(languageVal === "en-US")) {
      // if(flagImage){
      //   document.getElementById("Flag").src = flagImage
      // }

      const headerLinks = site.headerLinks;
      const footerLinks = site.footerLinks;
      headerLinks.forEach((menuItem)=>{
        if(menuItem.internalLink){
          menuItem.internalLink = getNewLink(menuItem.internalLink)
        }
        if(menuItem.links){
          menuItem.links.forEach((subItem)=>{
            if(subItem.internalLink){
              subItem.internalLink = getNewLink(subItem.internalLink)
            }
          })
        }
      })
      footerLinks.forEach((menuItem)=>{
        if(menuItem.internalLink){
          menuItem.internalLink = getNewLink(menuItem.internalLink)
        }
        if(menuItem.sublinks){
          menuItem.sublinks.forEach((subItem)=>{
            if(subItem.internalLink){
              subItem.internalLink = getNewLink(subItem.internalLink)
            }
          })
        }
      })
    }
    if (sessionStorage.getItem('showAlert') === 'hide') {
      setIsShowAlert(false)
    }
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpandLogin(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener("resize", updateHeightAnchor)
    return () => {
      window.removeEventListener("resize", updateHeightAnchor)
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkIsHomeFunc = () => {
    return page?.title === 'Home'
  }

  const updateHeightAnchor = () => {
    let count = 0
    let oldHeight = 0
    const getHeightHeader = setInterval(() => {
      if (!headerRef?.current) return
      let height = isHome === 0 ? 0 : headerRef.current.offsetHeight
      count++
      if (height !== oldHeight) {
        count = 0
        oldHeight = height
        setHeightHeader(height)
      }
      if (count > 50) {
        clearInterval(getHeightHeader)
      }
    }, 100);
  }

  const openMenu = () => {
    if (typeof document !== "undefined") {
      let html = document.querySelector("html")
      if (type === "dynamic" || type === "fixed") {
        //store current scroll position
        scrollTop.current = -html.getBoundingClientRect().top
        html.style.top = `${html.getBoundingClientRect().top}px`
      }
      html.classList.add("preventBodyScroll")
    }
    setMenuIsOpen(true)
  }

  const closeMenu = () => {
    if (typeof document !== "undefined") {
      let html = document.querySelector("html")
      html.classList.remove("preventBodyScroll")
      if (type === "dynamic" || type === "fixed") {
        html.scrollTop = scrollTop.current
        prevScroll.current = scrollTop.current
      }
    }
    setMenuIsOpen(false)
  }

  const handleMenuClick = (e, link) => {
    //check if a hashtag link - sometimes the scroll doesn't work due to the menu being open
    closeMenu()
    if (link.internalLink?.includes("#")) {
      navigate(link.internalLink, { replace: true })
    }
  }

  //Effects for component mounting and unmounting
  useEffect(() => {
    const html = document.querySelector("html")
    html.classList.remove("preventBodyScroll")
  }, [])

  useEffect(() => {
    if (type === "dynamic") {
      handleScrollDynamic()
      window.addEventListener("scroll", handleScrollDynamic)
      window.addEventListener("resize", handleScrollDynamic)
    }
    if (type === "fixed") window.addEventListener("scroll", handleScrollFixed)
    if (type === "static") {
      setPositionHeader('')
      window.addEventListener("scroll", handleScrollStatic)
      window.addEventListener("resize", handleScrollStatic)
    }
    return () => {
      if (type === "dynamic") window.removeEventListener("scroll", handleScrollDynamic)
      if (type === "fixed") window.removeEventListener("scroll", handleScrollFixed)
      if (type === "static") window.removeEventListener("scroll", handleScrollStatic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, heightHeader, isShowAlert])

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    const checkIsHome = checkIsHomeFunc()
    setIsHome(checkIsHome)
    setLoaded(true)
    updateHeightAnchor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const handleScrollDynamic = () => {
    if (!anchorRef?.current) return
    const anchorRect = anchorRef?.current?.getBoundingClientRect()
    const headerRect = headerRef?.current?.getBoundingClientRect()
    let position = 0
    if (anchorRect.top > 0) {
      position = anchorRect.top
    }
    const scroll = window.scrollY
    setPositionHeader(position)
    // if (isHome) {
    //   setHeightAnchor(0)
    // } else {
    setHeightAnchor(Math.round(headerRect.height) - 1)
    // }
    if (scroll > heightHeader) {
      setPinHeader(true)
    } else {
      setPinHeader(false)
    }
    if (scroll <= parseInt(heightHeader + 100)) {
      setVisible(true)
    } else if (prevScroll.current < scroll) {
      //down
      setVisible(false)
    } else if (prevScroll.current > scroll) {
      setVisible(true)
    }
    prevScroll.current = scroll
  }

  const handleScrollFixed = () => {
    const scroll = window.scrollY
    if (scroll > (heightHeader + 10)) {
      setPinHeader(true)
    } else {
      setPinHeader(false)
    }
  }

  const expandArrow = (e) => {
    e.preventDefault()
    setExpandLogin(!expandLogin)
  }

  const chooseLan = (e) => {
    if (e.target && e.target.getAttribute("value")) {
      const label = e.target.innerText

      if (isBrowser && label && label !== lan) {
        const val = e.target.getAttribute("value")
        let link = window.location.pathname.replace(`${languageVal}/`, "")
        if (val === "en-US") {
          window.location.href = `${window.location.protocol}//${window.location.host}${link}`
        } else {
          changePage(val, link)
        }
      }
    }
  }

  const changePage = (val, link) => {
    if (isHome) {
      window.location.href = `${window.location.protocol}//${window.location.host}/${val}/home`
    } else if (!window.location.href.includes(`/${val}/`)) {
      window.location.href = `${window.location.protocol}//${window.location.host}/${val}${link}`
    }
  }

  return (
    <>
      {isShowAlert && (
        <AlertBar closeAlert={closeAlert} {...announcementBar} />
      )}
      <header className={cx('relative', {
        overflowHidden: !loaded && page?.title !== 'Home'
      })}>
        <div className={cx("wrapper", type, {
          visible: visible,
          pinHeader: pinHeader,
          dynamicPosition: (type === 'dynamic' ? positionHeader > 0 : false) || false
        })}
          ref={headerRef}>
          <div className={cx('innerWrapper')}>
            {<PaddedContent>
              <div className={styles.topNav + ' ' + styles.relative}>
                <ul className={styles.navList}>
                  <li className={styles.relative}>
                    <span role="presentation" className={`${styles.dFlex} ${styles.menuItem} ${styles.alignCenter} ${styles.pointer} ${styles.topNavBtn}`} onBlur={expandArrow} onClick={expandArrow} ref={ref}>
                    {/* {flagImage && <img id="Flag" src={flagImage} className={styles.flagImage} alt=""></img>} */}
                    {lan}
                      <span className={`${styles.arrowExpand} ${expandLogin ? styles.expaned : ''}`} >
                        {icons.arrow}
                      </span>
                    </span>
                    <CSSTransition
                      in={expandLogin} /* dropdownIsOpen */
                      timeout={300} /* required */
                      mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
                      unmountOnExit
                      classNames={{
                        enter: styles.overflowHidden + ' ' + styles.animateDropdownEnter,
                        enterActive: styles.overflowHidden + ' ' + styles.animateDropdownEnterActive,
                        exit: styles.overflowHidden + ' ' + styles.animateDropdownExit,
                        exitActive: styles.overflowHidden + ' ' + styles.animateDropdownExitActive,
                        exitDone: styles.overflowHidden + ' ' + styles.animateDropdownExitDone,
                      }}
                    >
                      <div className={styles.dropdownContent}>
                        <div className={styles.submenuItemList} onClick={chooseLan}>
                          {(siteLinks?.languageDropdown || [])
                            .map((Language, index) => <div key={index} value={Language.languageCode} className={`${styles.submenuItem} ${Language.language == lan ? styles.activeMenu : ''}`}>
                              {/* {Language.flag && (<img src={Language.flag?.file.url} className={styles.flagImage} alt=""></img>)} */}
                              {Language.language}
                            </div>)}                
                        </div>
                      </div>
                    </CSSTransition>
                  </li>
                </ul>
              </div>
              <div className={styles.content} id="main-menu">
                <Link to={getNewLink("/home")} className={styles.logo} aria-label="Home">
                  <Logo />
                </Link>
                <nav>
                  <ul className={styles.navList}>
                    {site?.headerLinks.map((link, i) => {
                      if (link.title !== "HiddenMenu") {
                        return <DropdownLink
                          key={link.id}
                          pageJson={page}
                          index={i}
                          link={link}
                          lastItem={i === (site?.headerLinks.length - 1)}
                          typeHeader={type}
                        />
                      }
                      return null;
                    })}
                    <li className={styles.ctaWrap}>
                      {/* EC-8094: Unable to change link for main "Request a demo" CTA */}
                      <a href={getNewLink(getADemoLinks.internalLink)} className={styles.cta}>
                        {getADemoLinks.linkText}
                      </a>
                    </li>
                  </ul>
                </nav>
                <button
                  className={styles.hamburger}
                  onClick={openMenu}
                  aria-label="Open Menu"
                >
                  <Hamburger />
                </button>
              </div>
            </PaddedContent>}
            {/* <SubmenuBar /> */}
          </div>
        </div>
      </header>
      {<div style={heightAnchor > 0 ? { height: heightAnchor + 'px' } : {}} ref={anchorRef} className={heightAnchor > 10 ? styles.fakeBg : 'fake-bg'}></div>}
      {secondaryMenu && <SecondaryMenu page={page} secondaryMenu={secondaryMenu} pinHeader={!visible} type={type} />}

      <MobileMenu
        links={site.headerLinks}
        isOpen={menuIsOpen}
        closeMenu={closeMenu}
        pageJson={page}
        languageDropdown={siteLinks.languageDropdown}
        lan={lan}
        languageVal={languageVal}
        handleMenuClick={handleMenuClick}
      />
    </>
  )
}

export default Header
