import React from "react"
import { Link } from "gatsby"

import classNames from "classnames"
import { getNewLink } from "variables.js"
const ContentfulLink = ({
  className,
  ctaClassName,
  activeClassName,
  children,
  linkText, //not using this currently
  internalLink,
  externalLink,
  emailLink,
  type,
  onClick,
  openInNewTab,
  isHeader
}) => {
  if (!isHeader  && internalLink) {
    internalLink = getNewLink(internalLink)
  }
  const cta = type === "CTA"
  if (!internalLink && !externalLink && !emailLink) return null
  else if (internalLink) {
    //prioritize the internal link if for some reason both are provided
    externalLink = false
    emailLink = false
  }
  let linkClassNames = classNames(className, { [ctaClassName]: cta })
  return (
    <>
      {internalLink && (
        <Link
          className={linkClassNames}
          to={internalLink}
          onClick={onClick}
          activeClassName={activeClassName}
        >
          {children}
        </Link>
      )}

      {externalLink && (
        <a
          className={linkClassNames}
          href={externalLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {children}
        </a>
      )}

      {emailLink && (
        <a
          className={linkClassNames}
          href={`mailto:${emailLink}`}
          onClick={onClick}
        >
          {children}
        </a>
      )}
    </>
  )
}

export default ContentfulLink