export function mod(num, mod) {
  if (num >= 0) {
    return num % mod
  }
  return mod - (-num % mod)
}
const isBrowser = typeof window !== "undefined"
const lan = ["en-AU", "es-AR", "en-NZ", "en-CA", "fr-CA", "it-IT"]
export function getNewLink(internalLink) {
  if (isBrowser) {
    const pathname = window.location.pathname
    if (internalLink.includes("#")) {
      internalLink = pathname + internalLink
    } else {
      for (let i = 0; i < lan.length; i++) {
        if (!internalLink.includes(`/${lan[i]}/`) && pathname.includes(`/${lan[i]}/`)) {
          internalLink = "/" + lan[i] + internalLink
          break
        }
      }
    }
  }
  return internalLink
}

export function redirect() {
  if (isBrowser) {
    let pathname = window.location.pathname;
    const redirects = [
      {
        "fromPath": "/tgl/",
        "toPath": "/"
      },
      {
        "fromPath": "/es/tus/",
        "toPath": "/es-AR/"
      },
      {
        "fromPath": "/it/tus/",
        "toPath": "/it-IT/"
      },
      {
        "fromPath": "/fr/tus/",
        "toPath": "/fr-FR/"
      },
      {
        "fromPath": "/es/tca/",
        "toPath": "/es-AR/"
      },
      {
        "fromPath": "/fr/tca/",
        "toPath": "/fr-CA/"
      },
      {
        "fromPath": "/it/tca/",
        "toPath": "/it-IT/"
      },
      {
        "fromPath": "/es/tnz/",
        "toPath": "/es-AR/"
      },
      {
        "fromPath": "/fr/tnz/",
        "toPath": "/fr-FR/"
      },
      {
        "fromPath": "/it/tnz/",
        "toPath": "/it-IT/"
      },
      {
        "fromPath": "/tus/",
        "toPath": "/"
      },
      {
        "fromPath": "/tca/",
        "toPath": "/en-CA/"
      },
      {
        "fromPath": "/es/",
        "toPath": "/es-AR/"
      },
      {
        "fromPath": "/fr/",
        "toPath": "/fr-FR/"
      },
      {
        "fromPath": "/it/",
        "toPath": "/it-IT/"
      },
      {
        "fromPath": "/tnz/",
        "toPath": "/en-NZ/"
      },
    ]
    for (let i = 0; i < redirects.length; i++) {
      const item = redirects[i];
      const pattern = new RegExp(`^${item.fromPath}(.*?)$`);
      const result = pattern.exec(pathname);
      if (result && result[1]) {
        pathname = pathname.replace(new RegExp(item.fromPath, 'g'), item.toPath);
        return pathname;
      }
    }
  }

  return "";
}