import React, { useState, useEffect, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import styles from "styles/components/header/mobile-menu.module.scss"
import headerStyles from "styles/components/header/header.module.scss"
import ContentfulLink from "components/contentfulLink"
import ChevLeft from "images/icons/chev-left.svg"
import { searchActiveMenu } from "./helpers"

const SublinkItem = ({ link, classExtend, activeSubMenu }) => {
  return <ContentfulLink {...link} className={`${styles.dFlex} ${classExtend} ${styles.alignCenter} ${styles.submenuItem} ${activeSubMenu === link.internalLink ? styles.activeMenu : ''}`}>
    {link.linkText}
  </ContentfulLink>
}

const DropdownLinkMobile = ({ link, index, toggleSubmenu, activeMenu, pageJson }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [activeSubMenu, setSubActiveMenu] = useState('')
  const [activeMainMenu, setActiveMainMenu] = useState(false)

  const ref = useRef(null)
  if (!link.externalLink && !link.emailLink) {
    link.internalLink = link.internalLink || link.buttonLink || '/'
  }

  const getFirstPathName = (pathname = '') => {
    return pathname.split('/').filter(path => path !== '')[0]
  }
  useEffect(() => {
    const currentMenu = searchActiveMenu(link, pageJson)
    if (currentMenu.current) {
      setSubActiveMenu(currentMenu.current.internalLink)
    }
    const pathname = window.location.pathname
    if (link.buttonLink) {
      setActiveMainMenu(getFirstPathName(pathname) === (link.linkText || link.title).toLowerCase().replace(new RegExp(' ', 'i'), '-'))
    }
  }, [link, pageJson])

  useEffect(() => {
    setIsOpenDropdown(dropdownIsOpen && (index === activeMenu))
  }, [activeMenu, dropdownIsOpen, index])

  const toggleDropdown = (e) => {
    if (link.links) {
      if (!dropdownIsOpen) {
        e.preventDefault()
        setDropdownIsOpen(!dropdownIsOpen)
        toggleSubmenu(index, !dropdownIsOpen)
      } else {
        let html = document.querySelector("html")
        html.classList.remove("preventBodyScroll")
      }
    }
  }

  const backMenu = () => {
    setDropdownIsOpen(false)
    toggleSubmenu(-1, false)
  }

  return (
    <li className={styles.navItem + ' ' + (link.type === 'CTA' ? styles.btnCta : '')}>
      <div className={link.type === 'CTA' ? (`${styles.relative} ${styles.inlineBlock}`) : ""}>
        <CSSTransition
          in={activeMenu === -1 || index === activeMenu} /* dropdownIsOpen */
          timeout={300} /* required */
          mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
          unmountOnExit
          classNames={{
            enter: styles.animateDropdownEnter,
            enterActive: styles.animateDropdownEnterActive,
            exit: styles.animateDropdownExit,
            exitActive: styles.animateDropdownExitActive,
            exitDone: styles.animateDropdownExitDone,
          }}
        ><div className={`${styles.submenuLink} ${isOpenDropdown ? styles.opened : ''} ${activeMenu !== -1 ? styles.openSubmenu : ''}`} ref={ref}>
            <div className={`${styles.dFlex} ${styles.alignCenter} ${styles.justifyBetween} ${styles.submenuLinkWrapper}`}>
              <ContentfulLink
                {...link}
                className={`${styles.link}  ${activeMainMenu ? styles.activeMenu : ''}`}
                ctaClassName={styles.cta}
                onClick={toggleDropdown}
              >
                {link.linkText || link.title}
              </ContentfulLink>
              {isOpenDropdown && <span role="button" tabIndex={0} className={`${styles.mainMenuBtn} ${styles.dFlex} ${styles.alignCenter}`} onClick={backMenu} onKeyDown={backMenu}>
                <ChevLeft className={styles.chevLeft} />
                Main Menu
              </span>}
            </div>
          </div>
        </CSSTransition>
      </div>
      {link.links && <div className={styles.dropdown}>
        <CSSTransition
          in={isOpenDropdown} /* dropdownIsOpen */
          timeout={300} /* required */
          mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
          unmountOnExit
          classNames={{
            enter: headerStyles.animateDropdownEnter,
            enterActive: headerStyles.animateDropdownEnterActive,
            exit: headerStyles.animateDropdownExit,
            exitActive: headerStyles.animateDropdownExitActive,
            exitDone: headerStyles.animateDropdownExitDone,
          }}
        >
          <>
            <div className={styles.dropdownContent}>
              <div className={styles.submenuItemWrap}>
                <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.submenuItemListWrap}`}>
                  {(link.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} link={sublink} key={`${index}-${sublinkIdx}`} classExtend={styles.col12} />)}
                </div>
              </div>
            </div>
          </>
        </CSSTransition>
      </div>}
    </li >
  )
}

export default DropdownLinkMobile
