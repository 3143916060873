import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import styles from "styles/components/section-header.module.scss"
import { textWithBreaklineHtml } from 'helpers/helpers'

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const SectionHeader = ({
  label,
  title,
  subtext,
  centered,
  textAlignment,
  white,
  demibold,
  maxWidthTitle,
  maxWidthSubtext,
  topImage,
  disableTopImage,
  disableLabel,
  labelClass,
  childContentfulSectionHeadingContentTextNode,
  containerClass
}) => {
  return (
    <div
      className={[cx("sectionHeader", {
        centered: centered || textAlignment === 'Center',
        right: textAlignment === 'Right',
      }, { white: white }), containerClass].filter(Boolean).join(' ')}
    >
      {!disableTopImage && topImage?.file?.url && (
        <img src={topImage.file.url} className={cx('image')} alt={title || topImage?.title} />
      )}
      {!disableLabel && label && title && (
        <Reveal effect="fadeIn" duration={700}>
          <p className={[cx("sectionLabel"), labelClass].filter(Boolean).join(' ')}>
            <div
              dangerouslySetInnerHTML={{
                __html: textWithBreaklineHtml(label),
              }}
            />
          </p>
        </Reveal>
      )}
      {!disableLabel && label && !title && (
        <Reveal effect="fadeIn" duration={700}>
          {/* EC-7889: All text types displaying as title text when used by itself in Section Heading */}
          <div
            className={[cx("sectionLabel"), labelClass].filter(Boolean).join(' ')}
            dangerouslySetInnerHTML={{
              __html: textWithBreaklineHtml(label),
            }}
          />
        </Reveal>
      )}
      {title && (
        <Reveal effect="fadeUp" duration={700}>
          <h2
            className={cx("sectionTitle", { white: white })}
            style={{ maxWidth: maxWidthTitle }}
            dangerouslySetInnerHTML={{
              __html: textWithBreaklineHtml(title),
            }}
          />
        </Reveal>
      )}
      {subtext && subtext !== '' && (
        <Reveal effect="fadeUp" duration={700}>
          <p
            className={cx("subtext", { demibold: demibold || !title })}
            style={{ maxWidth: maxWidthSubtext }}
            dangerouslySetInnerHTML={{
              __html: textWithBreaklineHtml(subtext),
            }}
          />
        </Reveal>
      )}
      {childContentfulSectionHeadingContentTextNode?.childMarkdownRemark?.html && (
        <Reveal effect="fadeUp" duration={700}>
          <div className={cx("subtext", { demibold: demibold || !title , centered: centered })}
            style={{ maxWidth: maxWidthSubtext }}
              dangerouslySetInnerHTML={{
                __html: childContentfulSectionHeadingContentTextNode.childMarkdownRemark.html,
              }}
            />
        </Reveal>
      )}
    </div>
  )
}

export default SectionHeader
